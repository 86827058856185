
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions} from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { loginWithFacebook } from '@/core/services/FacebookService';
import { facebookApiInit } from '@/core/plugins/facebook.js';

declare global {
  interface Window {
    FB: any;
  }
}

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID;

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(facebookApiInit);

    // Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
        .label("Email"),
      password: Yup.string()
        .min(6)
        .required()
        .label("Password")
    });

    // Form submit function
    const onSubmitLogin = values => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          // Go to page after successfully login
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        })
        .finally(() => {
          // Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      fbAppId,
      loginWithFacebook,
    };
  },
});

import axios from 'axios';
import store from '@/store';
import {Actions, Mutations} from '@/store/enums/StoreEnums';
import router from '@/router';
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export const loginWithFacebook = () => {
  window.FB.login(
    (response) => {
      if (response && response.status === 'connected') {
        const loginWithFbBtn = (document.getElementById('login-with-facebook-btn') as HTMLElement);

        loginWithFbBtn.setAttribute("data-kt-indicator", "on");

        axios.post('/auth/social/facebook', {access_token: response.authResponse.accessToken})
          .then(response => {
            store.commit(Mutations.SET_AUTH, response.data);
            store.dispatch(Actions.REDIRECT_ON_AUTH).catch(() => {
              // proceed only if redirect did not happen
              router.push({ name: "dashboard" });
            })
          })
          .catch(error => {
            console.error(error);
            Swal.fire({
              text: 'Failed to authenticate with facebook',
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });

            loginWithFbBtn.removeAttribute("data-kt-indicator");
          });
      }
    },
    {
      // scope: 'public_profile,email', // @deprecated, config is used instead
      config_id: process.env.VUE_APP_FACEBOOK_APP_CONFIG_ID
    }
  );
};
